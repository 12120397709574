import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import { LocalizationContext } from "../layout/layout"

class SEO extends React.Component {

  render()
  {
    const { globalData, localize, seoData } = this.props
    const seoDefault = localize.lang === "pl" ? globalData?.pl?.nodes?.[0] || "" : globalData?.en?.nodes?.[0]  || "";

    const seoTitle = seoData.seoTitle || seoDefault.seoTitle || ""
    const seoDescription = seoData.seoDescription || seoDefault.seoDescription || ""
    const seoKeywords = seoData.seoKeywordsSource || seoDefault.seoKeywordsSource || ""

    const seoFacebookTitle = seoData.facebookOpengraphTitle || seoDefault.facebookOpengraphTitle || ""
    const seoFacebookDescription = seoData.facebookOpengraphDescription || seoDefault.facebookOpengraphDescription || ""
    const seoFacebookImg = (seoData.facebookOpengraphImage && (seoData.facebookOpengraphImage.length > 0 ? seoData.facebookOpengraphImage[0].url : seoDefault.facebookOpengraphImage.length > 0 ? seoDefault.facebookOpengraphImage[0].url : "")) || ""
    const seoFacebookImgDescription = seoData.facebookOpengraphImageDescription || seoDefault.facebookOpengraphImageDescription || ""

    const seoTwitterTitle = seoData.twitterTitle || seoDefault.twitterTitle || ""
    const seoTwitterDescription = seoData.twitterDescription || seoDefault.twitterDescription || ""
    const seoTwitterImg = (seoData.twitterImage && (seoData.twitterImage.length > 0 ? seoData.twitterImage[0].url : seoDefault.twitterImage.length > 0 ? seoDefault.twitterImage[0].url : "")) || ""
    const seoTwitterImgDescription = seoData.twitterImageDescription || seoDefault.twitterImageDescription || ""

      return (
          <>
            <title>{seoTitle}</title>
            <meta name="description" content={seoDescription}/>
            <meta name="keywords" content={seoKeywords}/>
            <meta name="og:title" content={seoFacebookTitle}/>
            <meta name="og:description" content={seoFacebookDescription}/>
            <meta name="og:type" content="website"/>
            <meta name="og:image" content={seoFacebookImg}/>
            <meta name="og:image:alt" content={seoFacebookImgDescription}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={seoTwitterTitle}/>
            <meta name="twitter:description" content={seoTwitterDescription}/>
            <meta name="twitter:image" content={seoTwitterImg}/>
            <meta name="twitter:image:alt" content={seoTwitterImgDescription}/>
          </>
      );
  }
}

const LocalizedSEO = React.forwardRef((props, ref) => {
    const data = useStaticQuery(graphql`
        query{
            pl: allCraftArcheeSeoGlobalSet(filter: { language: { eq: "pl" } }) {
                nodes {
                    seoTitle
                    seoDescription
                    seoKeywordsSource
                    facebookOpengraphDescription
                    facebookOpengraphImage {
                        url
                    }
                    facebookOpengraphImageDescription
                    facebookOpengraphTitle
                    twitterImage {
                        url
                    }
                    twitterDescription
                    twitterImageDescription
                    twitterTitle
                }
            }
            en: allCraftArcheeSeoGlobalSet(filter: { language: { eq: "en" } }) {
                nodes {
                    seoTitle
                    seoDescription
                    seoKeywordsSource
                    facebookOpengraphDescription
                    facebookOpengraphImage {
                        url
                    }
                    facebookOpengraphImageDescription
                    facebookOpengraphTitle
                    twitterImage {
                        url
                    }
                    twitterDescription
                    twitterImageDescription
                    twitterTitle
                }
            }
        }
    `);

    return (
        <LocalizationContext.Consumer>
            {localize => <SEO {...props} globalData={data} localize={localize} ref={ref}/>}
        </LocalizationContext.Consumer>
    )
})

export default LocalizedSEO


